import React, { useEffect, useState } from 'react';
import Paper from '../global/Paper';
export default function ResponsiveWrapper(props){
    const {
        children
    } = props;
    return(
        <div>
            {
                <Paper
                minWidth='80vw' maxWidth='90vw' height="100%" paddingLeft='15px'
                paddingRight='15px' margin="0 auto" boxSizing='border-box'
				>
                    {children}
                </Paper>
            }
        </div>
    )
}
