import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles({
    root: {
        '&::before': {
            borderBottom: '1px solid #E8B2B2'
          },
          '&:after': {
            borderBottom: '2px solid #E8B2B2',
            borderBottomColor: '#E8B2B2'
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid #E8B2B2',
            transition: 'border-bottom 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          }
    },
    dropdown: {
			width: "80%",
        fontFamily: 'Montserrat',
}
})

export default function Dropdown(props){
    const classes = useStyles();
    const { colorways, onChange, value } = props;

    const selectClasses = classNames(classes.root, classes.dropdown);

    return(
        <div>         
            <Select
            className={selectClasses}
            variant='outlined'
            value={value} 
            onChange={onChange}
			displayEmpty
            inputProps={{
                name: 'colorways',
                id: 'colorway-select'
            }}>
   			<MenuItem value="" disabled> 
				Select 
   			</MenuItem> 
                {
                    colorways.map((itm)=>{
                        return(
                            <MenuItem value={itm.name} key={`${itm.name}-dropdown-item`}>
                                {itm.name}
                            </MenuItem>
                        )
                    })
                }
            </Select>

        </div>
    )
}
