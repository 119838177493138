import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Helmet } from "react-helmet";
import Lightbox from "react-image-lightbox";
import classnames from "classnames";
import styles from "./ItemTemplate.module.css";
import Dropdown from "../components/Item/Dropdown";
import ResponsiveWrapper from "../components/Item/ResponsiveWrapper";
import { makeStyles } from "@material-ui/styles";
import { FiDownload } from "react-icons/fi";
import Button from "@material-ui/core/Button/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select/Select";
import classNames from "classnames";
import Img from "gatsby-image";
import slug from "slug";
import "react-image-lightbox/style.css";

const useStyles = makeStyles({
	root: {
		"&::before": {
			borderBottom: "1px solid #E8B2B2",
		},
		"&:after": {
			borderBottom: "2px solid #E8B2B2",
			borderBottomColor: "#E8B2B2",
		},
		"&:hover:not(.Mui-disabled):before": {
			borderBottom: "2px solid #E8B2B2",
			transition: "border-bottom 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		},
	},
	dropdown: {
		fontFamily: "Montserrat",
	},
});

export default function (props) {
	const context = props.pageContext.node;
	const {
		name,
		collection,
		specs,
		colorways,
		price,
		description,
		tearsheet,
	} = context;
	const materialClasses = useStyles();
	let initMainImg = colorways[0].path.childImageSharp.fluid;
	let initMainColor = colorways[0].name;
	const buyButtonClasses = classNames(
		styles.buyButton,
		"snipcart-add-item",
		styles.draw
	);
	const Slug = slug(name);

	const allImages = colorways.map(
		({ path }) => path.childImageSharp.fluid.src
	);
	// State
	const [main, setMainImg] = useState(initMainImg);
	const [currentColor, setCurrentColor] = useState(initMainColor);
	const [quantity, setQuantity] = useState(1);
	const [lightboxOpen, setLightboxOpen] = useState(false);
	const [lightboxIndex, setLightboxIndex] = useState(0);
	const [isSample, setSample] = useState(false);
	const [showTearsheet, setShowTearsheet] = useState(false);

	const _handleColorChange = (e) => {
		const name = e.target.value;
		setCurrentColor(name);
		const foundObj = colorways.find((obj) => {
			return obj.name === name;
		});
		setMainImg(foundObj.path.childImageSharp.fluid);
	};

	const _handleImageClick = (obj) => {
		let path = obj.path.childImageSharp.fluid;
		setCurrentColor(obj.name);
		setMainImg(path);
	};
	const _handleQuantityChange = (e) => {
		const quantity = e.target.value;
		if (quantity === "Sample") {
			setQuantity("Sample");
			setSample(true);
		} else {
			setQuantity(quantity);
			setSample(false);
		}
	};
	let nums = ["Sample"];
	for (let i = 1; i < 100; i++) {
		nums.push(i);
	}
	return (
		<Layout key={`${name}-page`} style={{ backgroundColor: "#e8b2b2" }}>
			<Helmet>
				<title>{name} | Circa Wallcoverings</title>
				<meta name="Description" content={`${name} - ${description}`} />
			</Helmet>
			<div className={styles.paperWrapper}>
				<ResponsiveWrapper>
					<div className={styles.colWrapper}>
						<div className={styles.col} id={"col1"}>
							<div
								className={styles.imgContainer}
								onClick={() => setLightboxOpen(true)}
							>
								<Img
									fluid={main}
									className={styles.mainImage}
									alt={"main"}
								/>
							</div>
							<div className={styles.lowerImages}>
								{colorways.map((obj, i) => {
									let path = obj.path.childImageSharp.fluid;
									if (path === main) {
										return <div key={`empty-${i}`}></div>;
									} else {
										return (
											<div
												data-obj={obj}
												key={`${obj.name}-image-${i}`}
												onClick={() =>
													_handleImageClick(obj)
												}
											>
												<Img
													fluid={path}
													className={
														styles.nonMainImgs
													}
													alt={`${name} - ${obj.name}`}
												/>
											</div>
										);
									}
								})}
							</div>
						</div>
						<div className={styles.col} id="col2">
							<Img
								imgStyle={{
									height: "100%",
									width: "100%",
									objectFit: "contain",
								}}
								className={styles.tearsheetImg}
								fluid={tearsheet.childImageSharp.fluid}
							/>
							<div className={styles.tearsheetBtnGroup}>
								<a
									className={styles.tearsheetBtn}
									href={
										tearsheet.childImageSharp.fluid
											.originalImg
									}
									download
								>
									<FiDownload />
									Download
								</a>
							</div>
						</div>
						<div className={styles.col} id="col3">
							<div className={styles.infoRow}>
								<h1 className={styles.name}>{name}</h1>
								<p className={styles.collection}>
									{collection}
								</p>
							</div>
							<div
								className={styles.infoRow}
								style={{ marginTop: "3px" }}
							>
								<p>{description}</p>
							</div>
							<div className={styles.infoRow}>
								<label>Dimensions:</label>
								<p className={styles.specs}>{specs}</p>
							</div>
							<div className={styles.infoRow}>
								<label>Colorway:</label>
								<Dropdown
									colorways={colorways}
									onChange={(obj) => {
										_handleColorChange(obj);
									}}
									value={currentColor}
								/>
							</div>
							<div className={styles.infoRow}>
								<label>Quantity:</label>
								<div>
									<Select
										value={quantity}
										onChange={(e) => {
											_handleQuantityChange(e);
										}}
										className={materialClasses.root}
									>
										{nums.map((num, i) => (
											<MenuItem
												value={num}
												key={`quantity-${i}`}
											>
												{num}
											</MenuItem>
										))}
									</Select>
								</div>
							</div>
							<div className={styles.infoRow}>
								<h3 style={{ fontWeight: 400 }}>
									${price} /{" "}
									{name === "Superstar"
										? " roll (6 faces)"
										: "yard"}
								</h3>
							</div>
							<div className={styles.infoRow}>
								<Button
									title="Buy"
									className={buyButtonClasses}
									data-item-id={name}
									data-item-name={name}
									data-item-description={currentColor}
									data-item-quantity={isSample ? 1 : quantity}
									data-item-url={`https://circa.netlify.com/shop/${Slug}`}
									data-item-price={isSample ? 10 : price}
								>
									Add to cart
								</Button>
							</div>
						</div>
					</div>
				</ResponsiveWrapper>
			</div>
			{lightboxOpen ? (
				<Lightbox
					mainSrc={
						colorways[lightboxIndex].path.childImageSharp.fluid
							.originalImg
					}
					imageCaption={colorways[lightboxIndex].name}
					nextSrc={
						colorways[(lightboxIndex + 1) % colorways.length].path
							.childImageSharp.fluid.originalImg
					}
					prevSrc={
						colorways[
							(lightboxIndex + colorways.length - 1) %
								colorways.length
						].path.childImageSharp.fluid.originalImg
					}
					onCloseRequest={() => setLightboxOpen(false)}
					onMoveNextRequest={() =>
						setLightboxIndex(
							(lightboxIndex + colorways.length - 1) %
								colorways.length
						)
					}
				/>
			) : (
				<></>
			)}
		</Layout>
	);
}
